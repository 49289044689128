import React from "react";
import Helmet from "react-helmet";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: ""
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  canBeSubmitted() {
    const { name, email, message } = this.state;
    return name.length > 0 && email.length > 0 && message.length > 0;
  }

  handleSubmit = (e) => {
    if (this.canBeSubmitted()) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then(() => alert("Your message has been sent."))
        .then(() =>
          this.setState({
            name: "",
            email: "",
            message: ""
          })
        )
        .catch((error) => alert(error));
    }

    e.preventDefault();
  };

  render() {
    const submitDisabled = !this.canBeSubmitted();
    return (
      <div className="container">
        <Helmet title={"Matt Williams | Get In Touch"} />
        <div className="row justify-content-center align-items-center ">
          <div className="col-md-8">
            <h2 className="mb-5">
              <span>Get In Touch</span>
            </h2>
            <div className="mb-5">
              <i className="fab fa-twitter mr-2" />:{" "}
              <a href="https://twitter.com/mwilliamsdev">@mwilliamsdev</a>
            </div>
            <form
              name="contact"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
              className=""
            >
              <div hidden>
                <label>Don’t fill this out: </label>
                <input name="bot-field" />
              </div>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="John Smith"
                  className="form-control"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="johnsmith@gmail.com"
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea
                  name="message"
                  className="form-control"
                  value={this.state.message}
                  onChange={this.handleChange}
                  rows="2"
                />
              </div>
              <div className="form-group">
                <button
                  disabled={submitDisabled}
                  type="submit"
                  className="btn btn-primary"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
